<template>
  <ReflectTable
    :columns="
      columns.map(c => ({
        key: c.shortTitle,
        value: c.value,
        isSortable: true
      }))
    "
    :sorting-by-columns="sortingByColumns"
    :is-in-modal="isInModal"
    @update-columns-sorting="updateColumnsSorting"
  >
    <tr
      v-for="(item, j) in items"
      :key="j"
      class="body__row"
    >
      <td
        v-for="(column, i) in columns"
        :key="column.shortTitle"
        :ref="i === 0 ? 'firstColumnItems' : undefined"
        class="row__value"
        :class="{
          'row__value--selected': sortingByColumns[column.shortTitle] !== null,
          'row__value--early-end':
            items.length < MAX_ITEMS_PER_PAGE && j === items.length - 1
        }"
      >
        {{
          formatMemberValue(
            item[column.name]!,
            column.format,
            FormatContext.DEFAULT,
            false
          )
        }}
      </td>
    </tr>
  </ReflectTable>
</template>

<script lang="ts">
import { MAX_ITEMS_PER_PAGE } from './ListQueryRenderer.vue'
import {
  type ExplorerTableColumn,
  type SortingByColumn
} from '~/types/analytics'
import { formatMemberValue } from '~/services/analyticsFormat'
import type { ResultSetItem, ResultSetItemValue } from '~/types/query'
import { FormatContext } from '~/types/analyticsFormat'

export default {
  name: 'DataList',
  props: {
    columns: {
      type: Array as PropType<ExplorerTableColumn[]>,
      default: () => []
    },
    items: {
      type: Array as PropType<ResultSetItem[]>,
      default: () => []
    },
    sortingByColumns: {
      type: Object as PropType<SortingByColumn>,
      default: null
    },
    isInModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update-columns-sorting'],
  setup() {
    const firstColumnItems = ref<
      InstanceType<typeof HTMLTableCellElement>[] | null
    >(null)

    return {
      firstColumnItems,
      formatMemberValue,
      FormatContext,
      MAX_ITEMS_PER_PAGE
    }
  },
  mounted() {
    this.groupFirstColumnMatchingCells()
  },
  updated() {
    // We need to wait for the table to re render to group tables cells
    this.groupFirstColumnMatchingCells()
  },
  methods: {
    groupFirstColumnMatchingCells() {
      let previousValue: ResultSetItemValue | undefined

      const firstColumnName = this.columns[0]?.name

      if (this.firstColumnItems) {
        this.firstColumnItems.forEach((node, index) => {
          const value = this.items[index]![firstColumnName!]
          // Check if the cell value is the same as the previous one
          if (value === previousValue) {
            // Hide node content when the value is the same than last row
            node.setAttribute('class', 'row__value row__value--hide')
          } else {
            if (previousValue !== undefined) {
              const parentNode = node.parentNode as HTMLTableRowElement
              parentNode.setAttribute(
                'class',
                'body__row body__row--with-border'
              )
            }
            previousValue = value
          }
        })
      }
    },
    updateColumnsSorting(columnKey: string) {
      this.$emit('update-columns-sorting', columnKey)
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  border-collapse: separate;
  width: 100%;

  &__body {
    overflow: auto;

    .body {
      &__row {
        &--with-border .row__value {
          border-top: 1px solid $bg-primary;
        }

        &:nth-child(even) {
          background-color: $bg-quaternary;
        }

        &:nth-child(odd) {
          background-color: $bg-secondary;
        }

        .row__value {
          @include font-text($font-weight-regular);
          @include font-size($font-size-mini);
          padding: 7px $margin-regular;
          vertical-align: middle;
          border-left: 1px solid $bg-primary;
          box-sizing: border-box;

          &:first-child {
            border-left: 0;
          }

          &--hide {
            opacity: 0;
          }

          &--early-end {
            border-bottom: 1px solid $bg-primary;
          }

          &--selected {
            @include font-text($font-weight-book);
          }
        }

        &:first-child .row__value {
          border-top: none;
        }
      }
    }
  }
}
</style>
